@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  text-align: #{$ldirection};
  line-height: 1.8;
  overflow: hidden;
  width: auto;
  margin-inline: 19px 16px;
  .header-offers-banner-formatter__show-arrow & {
    margin: 0 -10px;
  }
  @include breakpoint($landscape-up) {
    margin: 0;
  }
  &__offer-text {
    display: inline;
    line-height: get-line-height(14px, 14px);
    p {
      display: inline;
      margin: 0;
      font-size: 13px;
      font-weight: 500;
      line-height: get-line-height(14px, 14px);
    }
  }
  a {
    font-size: 13px;
    color: inherit;
  }
}
